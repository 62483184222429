/* overrides default ag-grid styles */

.ag-theme-quartz {
  @apply font-figtree #{!important};

  --ag-background-color: var(--color-white) !important;
  --ag-tooltip-background-color: var(--color-white) !important;
  --ag-header-background-color: var(--color-gray-50) !important;
  --ag-foreground-color: var(--color-gray-700) !important;
  --ag-border-color: var(--color-gray-200) !important;
  --ag-active-color: var(--color-indigo-500) !important;
  --ag-selected-row-background-color: var(--color-indigo-100) !important;
  --ag-row-hover-color: var(--color-indigo-50) !important;
}

.dark .ag-theme-quartz {
  --ag-background-color: var(--color-gray-800) !important;
  --ag-tooltip-background-color: var(--color-gray-800) !important;
  --ag-header-background-color: var(--color-gray-700) !important;
  --ag-foreground-color: var(--color-gray-100) !important;
  --ag-border-color: var(--color-gray-600) !important;
  --ag-active-color: var(--color-indigo-400) !important;
  --ag-selected-row-background-color: var(--color-gray-600) !important;
  --ag-row-hover-color: #ffffff12 !important;
}
