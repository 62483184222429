.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  z-index: 20;
  @apply bg-primary-500/40;
}

.resizer.isResizing {
  opacity: 1;
  @apply bg-primary-500;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
