.supabase-auth-ui_ui-container {
  @apply text-secondary;
}

.supabase-auth-ui_ui-message {
  @apply p-2;
  @apply bg-yellow-300;
  @apply rounded-lg;
  @apply font-medium;
  @apply text-yellow-700 #{!important};
}

.supabase-auth-ui_ui-button:not([type='submit']) {
  @apply bg-transparent #{!important};
  @apply border #{!important};
  @apply border-solid #{!important};
  @apply border-indigo-300 #{!important};
  @apply text-indigo-500 #{!important};
}

.supabase-auth-ui_ui-button:not([type='submit']):hover {
  @apply border-indigo-400 #{!important};
}

.supabase-auth-ui_ui-input,
.supabase-auth-ui_ui-button {
  @apply p-2 #{!important};
  @apply rounded-md #{!important};
}