@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .required-label {
    @apply after:ml-0.5 after:text-danger-500 after:content-['*'];
  }
}

@layer utilities {
  .flex-center {
    @apply flex items-center justify-center;
  }

  .bordered {
    @apply border-[0.5px] border-divider/15 dark:border-divider/5;
  }

  .container-shadow {
    @apply bg-content1 shadow-medium rounded-medium p-3;
  }

  .container-border {
    @apply bg-content1 bordered rounded-medium p-3;
  }

  .h3 {
    @apply text-small leading-5;
  }

  .section-description {
    @apply text-xs leading-5 sm:leading-6 text-default-400 tracking-tight;
  }

  .interactive-hover {
    @apply rounded-small p-1.5 hover:text-foreground-600 hover:bg-default/30;
  }

  .dense-detail-value-min-h {
    @apply min-h-[30px];
  }
  .hide-number-arrows {
    @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none;
  }
}

@import './globals.scss';
@import './ck.scss';
@import './ag-grid.scss';
@import './uploadcare.scss';
@import './nprogress.scss';
@import './supabase.scss';
@import './react-pdf.scss';
@import './table.scss';
