.ck-editor__editable_inline p {
  font-size: 14px !important;
  font-family: 'Times New Roman' !important;
}

.ck.ck-editor__editable_inline.ck-blurred ::selection {
  @apply bg-indigo-200 #{!important};
  @apply bg-opacity-80 #{!important};
}

.ck-button {
  @apply border-none shadow-none #{!important};
  @apply hover:shadow-none #{!important};
}

.ck-content .table table {
  position: relative;
  max-width: 800px;
  display: block;
  overflow: scroll !important;

  /* For some reason <td> elements sometimes have large fixed widths. Crude solution is to restrict width of the parent table and allow overflow */
}

.main-editor-container .ck.ck-content {
  @apply overflow-x-hidden #{!important};
}

.ck-content > ol,
.ck-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck-content td {
  @apply dark:border-white #{!important};
}

.ck.ck-pagination-view-line::after {
  @apply text-gray-100 #{!important};
  @apply font-bold #{!important};
  @apply bg-gray-400 #{!important};
  @apply dark:bg-gray-600 #{!important};
  @apply rounded-r-sm #{!important};
}

.ck .ck-suggestion-marker {
  &.ck-suggestion-marker-deletion,
  &.ck-suggestion-marker-insertion {
    @apply border-none #{!important};
  }
}

.ck-content .ck-suggestion-marker-deletion-new {
  opacity: 0.9;
  @apply decoration-red-600/60 decoration-2 dark:decoration-red-300/30;
}

/* Preview Comments */
.ck-content .ck-suggestion-preview-marker-deletion {
  text-decoration: none !important;
}

.ck .ck-suggestion-marker.ck-suggestion-marker-insertion,
.ck-suggestion-preview-marker.ck-suggestion-preview-marker-insertion > span,
.ck .ck-suggestion-marker.ck-suggestion-marker-insertion * {
  @apply text-green-700  dark:text-green-400 #{!important};
}

.ck-suggestion-preview-marker.ck-suggestion-preview-marker-deletion > span {
  @apply border-none;
}

.ck-suggestion-preview-marker.ck-suggestion-preview-marker-deletion,
.ck-suggestion-preview-marker.ck-suggestion-preview-marker-deletion *,
.ck .ck-suggestion-marker.ck-suggestion-marker-deletion,
.ck .ck-suggestion-marker.ck-suggestion-marker-deletion * {
  @apply text-red-700 dark:text-red-400 #{!important};
}

.ck .ck-suggestion-marker.ck-suggestion-marker-deletion {
  text-decoration-thickness: 2px;
}

.ck-main-toolbar .ck.ck-toolbar:not(.ck-ai-form__toolbar) {
  @apply bg-transparent #{!important};
  @apply border-none #{!important};

  .ck-toolbar__items {
    > .ck-dropdown
      > .ck-splitbutton.ck-dropdown__button
      > .ck-button.ck-off:not(:hover),
    > .ck-dropdown > .ck-button.ck-off:not(:hover),
    > .ck-button.ck-off:not(:hover) {
      @apply bg-transparent #{!important};
    }
  }
}

.ck.ck-button:focus {
  @apply border-indigo-500 #{!important};
}

.ck.ck-list__item {
  .ck.ck-button.ck-off.ck-button_with-text {
    @apply py-2 #{!important};
  }
}

.ck.ck-insert-table-dropdown-grid-box {
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 3px !important;

  &.ck-off {
    @apply border-gray-300 dark:border-default-400 #{!important};
  }
  &.ck-on {
    @apply border-indigo-700 bg-indigo-50 hover:bg-indigo-100 dark:border-primary-400 dark:bg-primary-400/20 dark:hover:bg-primary-400/5 #{!important};
  }
}

.ck.ck-tooltip span {
  @apply text-gray-200 #{!important};
}

.ck.ck-editor__editable_inline {
  /* @apply border #{!important}; */
  border-style: solid !important;
  @apply border border-transparent shadow-none #{!important};

  &.ck-focused {
    @apply border-indigo-400/30 dark:border-indigo-400/20 #{!important};
  }
}

.ck {
  ::marker {
    font-weight: 600 !important;
  }

  li > ol {
    /* Hack to ensure nested lists are indented correctly. Value may conflict with block indentation settings defined in custom ck editor. */
    padding-left: 2em;
  }
}

@keyframes highlight {
  0% {
    @apply bg-yellow-500;
  }
  100% {
    @apply bg-yellow-500/20;
  }
}

@keyframes highlight-dark {
  0% {
    @apply bg-yellow-400;
  }
  100% {
    @apply bg-yellow-400/20;
  }
}

.ck-find-result_selected {
  animation: highlight 800ms ease-out forwards !important;
}
:is(.dark .ck-find-result_selected) {
  animation: highlight-dark 800ms ease-out forwards !important;
}

.ck-find-result {
  @apply bg-transparent #{!important};
}

#revision-viewer-container {
  display: none;
}

.revision-editor-container > .ck-editor {
  position: relative;
}

.revision-editor-container .ck-editor__top .ck-toolbar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.revision-editor-container .ck-editor__editable_inline {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sidebar-container {
  position: relative;
  overflow: hidden;
  margin-left: -1px;
}

.ck-suggestion__info-name.ck-annotation__info-name {
  @apply font-bold dark:text-gray-100 #{!important};
}

.ck-comment__info-time.ck-annotation__info-time {
  @apply font-semibold dark:text-gray-400 #{!important};
}

.ck-suggestion-type {
  @apply font-normal dark:text-gray-100 #{!important};
}

.ck-annotation__content-wrapper > p {
  @apply font-semibold dark:text-gray-200 #{!important};
}

/* Revision history top bar */
.ck.ck-sticky-panel__content,
.ck.ck-sticky-panel__placeholder,
.ck.ck-editor__top.ck-reset_all {
  @apply border-none dark:bg-gray-800 #{!important};
}

/* Revision history Editor */
.ck-reset.ck-editor {
  @apply flex-1 bg-transparent #{!important};

  .ck-toolbar {
    @apply bg-transparent #{!important};
    @apply border-none #{!important};

    .ck-toolbar__items {
      > .ck-button.ck-off:not(:hover):not(
          .ck-revision-history-ui__back-to-editing
        ) {
        @apply bg-transparent #{!important};
      }
    }
  }
}

.ck.ck-editor__main > .ck-editor__editable {
  background: transparent !important;
}

/* CK Loader */
.ck.ck-editor__editable {
  &:before {
    @apply bg-transparent #{!important};
  }
  &:after {
    @apply border-t-primary-500 #{!important};
  }
  &.ck-editor__editable__loading-overlay {
    @apply h-[80vh] bg-transparent #{!important};
  }
}

.ck.ck-reset {
  .ck.ck-editor__main {
    @applyborder-none dark:bg-transparent #{!important};
    > .ck-editor__editable {
      @apply border-none bg-transparent #{!important};

      &::before {
        @apply bg-none #{!important};
      }

      &.ck-focused {
        @apply dark:bg-transparent #{!important};
      }

      &::after {
        @apply border-t-primary-500 dark:border-t-primary-400 #{!important};
      }
    }
  }

  .ck-revision-history-ui__changes-navigation__navigation button {
    @apply cursor-pointer border-none bg-transparent hover:bg-gray-100 dark:hover:bg-gray-700 #{!important};
  }
}

/* Tooltip */
.ck-balloon-rotator__content {
  border-radius: var(--ck-border-radius) !important;
  .ck-annotation.ck-revision-change {
    border-bottom-left-radius: var(--ck-border-radius) !important;
  }
}
.ck-balloon-panel.ck-balloon-panel_with-arrow {
  @apply dark:text-gray-100 #{!important};

  &::after {
    border-bottom-color: var(--ck-color-panel-background) !important;
    @apply z-20 #{!important};
  }
}

.ck-revision-change span {
  @apply dark:text-gray-100 #{!important};
}

.ck-revision-change .ck-revision-change__label {
  @apply dark:text-gray-300 #{!important};
}

/* Revision History Sidebar */
.ck-revision-history-sidebar {
  .ck-revision-history-sidebar__timeline {
    @apply pb-16 #{!important};
  }

  .ck.ck-revision-history-sidebar__header {
    @apply border-none #{!important};
  }
  .ck-revision-history-sidebar__time-period__label {
    @apply bg-gray-400 dark:bg-gray-600 dark:text-white #{!important};
  }

  .ck-revision-history-sidebar__revision {
    @apply dark:text-white/90 dark:shadow-sm dark:shadow-black/80 #{!important};
    @apply border-l-indigo-500 dark:border-l-indigo-500/80 #{!important};

    &.ck-revision-history-sidebar__revision_selected {
      @apply border-l-transparent bg-indigo-500 dark:bg-indigo-600 #{!important};

      .ck.ck-dropdown .ck-button.ck-dropdown__button {
        @apply bg-indigo-500 text-gray-100 hover:bg-indigo-600 dark:bg-indigo-600 dark:text-gray-300 dark:hover:bg-indigo-700 #{!important};
      }

      .ck-revision-history-sidebar__revision-name .ck-input {
        @apply dark:text-white/90 #{!important};
      }

      .ck-list__item .ck-button.ck-button_with-text {
        @apply text-gray-900 dark:text-gray-300 #{!important};
      }
    }

    /* Selected revisions to compare */
    &.ck-revision-history-sidebar__revision_highlighted {
      @apply border-l-transparent bg-indigo-400/20 dark:bg-indigo-900/70 #{!important};

      .ck.ck-dropdown .ck-button.ck-dropdown__button {
        @apply text-gray-900 hover:bg-indigo-500/20 dark:bg-indigo-500/20 dark:text-gray-300 dark:hover:bg-indigo-800 #{!important};
      }
    }

    .ck-revision-history-sidebar__revision__actions .ck-dropdown__button {
      @apply text-gray-900 dark:text-white/90 #{!important};
    }
  }
}

.ck.ck-input {
  &:read-only {
    @apply pointer-events-none select-text #{!important};
  }
  &:focus {
    @apply border-none shadow-none outline-none ring-2 ring-indigo-500 dark:ring-indigo-400 #{!important};
  }
}

/* Forms */
.ck-reset {
  .ck.ck-dropdown .ck-button.ck-dropdown__button,
  .ck.ck-dropdown .ck-button.ck-dropdown__button:focus {
    @apply cursor-pointer border-0 shadow-none ring-transparent dark:hover:bg-gray-700  #{!important};
  }

  .ck.ck-list__item .ck-button {
    @apply border-none #{!important};
  }
}

.ck.ck-reset.ck-dropdown__panel,
.ck.ck-dialog {
  @apply rounded-md border border-gray-100/5 shadow-md dark:border-gray-700 #{!important};
  .ck-form__header {
    .ck-button {
      @apply cursor-pointer dark:hover:bg-gray-600 #{!important};
    }
  }

  .ck.ck-dialog__actions {
    .ck.ck-button.ck-off.ck-button_with-text {
      @apply cursor-pointer space-x-1 rounded-md border border-solid border-gray-300 text-gray-600 hover:bg-indigo-300/20 dark:border-gray-600 dark:text-gray-300 dark:hover:border-indigo-300/20 dark:hover:bg-indigo-600/30 #{!important};

      &.ck-button-action {
        @apply bg-indigo-500 text-white hover:border-transparent hover:bg-indigo-600 dark:bg-indigo-600 dark:hover:bg-indigo-700 #{!important};
        * {
          @apply text-white  #{!important};
        }
      }
    }
  }
}

.ck.ck-button {
  &.ck-revision-history-ui__back-to-editing {
    @apply mr-3 border-transparent bg-indigo-500 text-white hover:bg-indigo-600/90 dark:bg-indigo-600 dark:hover:border-transparent dark:hover:bg-indigo-500/90 #{!important};
    > * {
      @apply text-white  #{!important};
    }
    &.ck-off:not(.ck-disabled) {
      @apply cursor-pointer #{!important};
    }
  }

  &.ck-splitbutton__action {
    &.ck-off {
      @apply rounded-r-none #{!important};
    }
  }

  &.ck-button_with-text {
    &.ck-switchbutton {
      @apply py-2 #{!important};
      .ck-button__toggle {
        @apply cursor-pointer rounded-xl py-0.5 shadow-none outline-none #{!important};
        .ck-button__toggle__inner {
          @apply rounded-full #{!important};
        }
      }
      &.ck-off {
        .ck-button__toggle__inner {
          @apply ml-0.5 #{!important};
        }
      }
    }
  }

  &.ck-annotation-counter {
    @apply text-gray-500 hover:text-gray-600 dark:text-white/60 dark:hover:text-white/90 #{!important};

    &.ck-off {
      @apply bg-transparent #{!important};
    }
  }

  &.ck-button--confidential {
    @apply ml-0.5 p-[4px] #{!important};
    svg {
      @apply h-[14px] w-[14px] #{!important};
    }
    &.ck-on {
      @apply bg-gray-200 text-indigo-500 dark:bg-gray-800/90 dark:text-indigo-500 #{!important};
    }
  }

  &.ck-ai-form__toggle-history {
    @apply mr-0.5 #{!important};
    &.ck-off {
      &:not(.ck-disabled) {
        @apply dark:hover:bg-gray-500/60 dark:active:bg-gray-500 #{!important};
      }
    }

    &.ck-on {
      @apply dark:bg-gray-500/80 dark:text-white/90 #{!important};
    }
  }

  &.ck-ai-form__submit {
    @apply text-primary #{!important};
    &.ck-disabled {
      @apply opacity-50 #{!important};
    }
    &.ck-off {
      &:not(.ck-disabled) {
        @apply transition-transform-colors-opacity hover:opacity-80 active:scale-[0.90] #{!important};
      }
    }
  }
}

.ck-thread__input .ck-content.ck-editor__editable.ck-focused {
  @apply border-none #{!important};
}

.ck-thread {
  background-color: var(
    --ck-color-annotation-wrapper-background-custom
  ) !important;
}

.ck-thread__remove-confirm {
  .ck-button.ck-off {
    @apply cursor-pointer bg-transparent hover:bg-white hover:bg-opacity-5 #{!important};
  }
}

.ck-suggestion-wrapper {
  border-radius: var(--ck-border-radius);
}

.ck-comment:after {
  @apply ml-0.5 w-[3px] #{!important};
}

.ck-comment__input-actions--submit {
  @apply border-none #{!important};
  &:hover {
    @apply dark:bg-indigo-500 #{!important};
  }
}

.ck-comment__input-actions--cancel {
  @apply border-none dark:bg-transparent #{!important};
  &:hover {
    @apply dark:bg-gray-700 #{!important};
  }
}

.ck.ck-find-and-replace-form__inputs {
  .ck.ck-results-counter {
    @apply dark:text-gray-300 #{!important};
  }
}

.ck-list .ck-list__group > .ck-label {
  @apply pb-1 pl-2 text-foreground-400 #{!important};
}

.ck-dropdown.ck-toolbar__nested-toolbar-dropdown
  .ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se,
.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_sw {
  @apply dark:border-gray-700 dark:bg-gray-900 #{!important};
}

.ck-ai-commands-dropdown > .ck-button:hover:not(.ck-off) {
  color: var(--ck-color-button-on-color) !important;
}

.ck .ck-ai-form .ck-ai-form-content .ck-ai-form__content-area-wrapper {
  border: none !important;
  @apply rounded-md #{!important};
}

.ck-find-result_selected {
  @apply bg-yellow-500/40 dark:bg-yellow-700/60 #{!important};
}

.ck-comment-marker {
  @apply border-none #{!important};
}

.ck.ck-splitbutton.ck-splitbutton_open
  > .ck-splitbutton__arrow:not(.ck-disabled):after,
.ck.ck-splitbutton:hover > .ck-splitbutton__arrow:not(.ck-disabled):after {
  @apply bg-transparent #{!important};
}

:root {
  --ck-border-radius: 4px !important;

  --ck-switch-button-toggle-width: 2.8153846154em !important;
  --ck-switch-button-translation: calc(
    var(--ck-switch-button-toggle-width) - var(
        --ck-switch-button-toggle-inner-size
      ) - 4px
  ) !important;

  .ck {
    --ck-color-button-on-color: #6366f1;
    --ck-color-button-on-background: #eef2ff;
    --ck-color-button-on-hover-background: #e0e7ff;
    --ck-color-button-on-active-background: #c7d2fe;
    --ck-color-list-button-on-background: #6366f1;

    --ck-color-button-action-background: hsl(243, 75%, 59%);
    --ck-color-button-action-hover-background: hsl(239, 84%, 67%);
    --ck-color-button-action-active-background: hsl(239, 84%, 65%);
    --ck-color-button-action-active-shadow: hsl(239, 83%, 63%);
    --ck-color-button-action-disabled-background: hsla(239, 84%, 71%, 0.5);

    --ck-color-switch-button-on-hover-background: hsl(239, 84%, 67%);
    --ck-color-switch-button-on-background: var(
      --ck-color-button-action-background
    );

    --ck-color-focus-border-coordinates: hsl(243, 75%, 59%);
    --ck-color-focus-border: hsl(243, 75%, 59%);
    --ck-color-focus-outer-shadow: hsl(239, 71%, 75%);

    --ck-color-comment-marker: hsla(55, 100%, 45%, 0.3);
    --ck-color-comment-marker-active: hsla(55, 100%, 45%, 0.5);
  }
}

:root[class~='dark'] {
  --ck-custom-background: hsl(270, 1%, 29%);
  --ck-custom-panel-background: hsl(270, 1%, 25%);
  --ck-custom-foreground: hsl(255, 3%, 18%);
  --ck-custom-border: hsl(300, 1%, 22%);
  --ck-custom-white: hsl(0, 0%, 98%);
  --ck-custom-popover-background: hsl(270, 1%, 29%);
  --ck-custom-button-hover-background: hsl(270, 1%, 32%);
  --ck-custom-button-active-background: hsl(270, 2%, 20%);

  --ck-color-base-background: var(--ck-custom-background);
  --ck-color-base-text: hsl(0, 0%, 98%);
  --ck-color-base-border: var(--ck-custom-border);

  --ck-color-base-action: hsl(243, 75%, 59%);

  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: hsl(257, 3%, 43%);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: var(
    --ck-custom-button-hover-background
  );
  --ck-color-button-default-active-background: var(
    --ck-custom-button-active-background
  );
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-save: hsl(84, 81%, 44%);
  --ck-color-button-cancel: hsl(0, 100%, 56%);

  .ck {
    --ck-color-button-on-color: hsl(234, 89%, 74%);
    --ck-color-button-on-background: hsla(234, 89%, 74%, 0.3);
    --ck-color-button-on-hover-background: hsla(234, 89%, 74%, 0.25);
    --ck-color-button-on-active-background: hsla(234, 89%, 74%, 0.35);
    --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
    --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

    --ck-color-button-action-background: hsl(243, 75%, 59%);
    --ck-color-button-action-hover-background: hsl(239, 84%, 67%);
    --ck-color-button-action-active-background: hsl(239, 84%, 65%);
    --ck-color-button-action-active-shadow: hsl(239, 83%, 63%);
    --ck-color-button-action-disabled-background: hsla(239, 84%, 71%, 0.3);
    --ck-color-button-action-text: var(--ck-custom-white);

    --ck-color-switch-button-inner-background: var(--ck-custom-white);
    --ck-color-switch-button-on-hover-background: hsl(239, 84%, 67%);
    --ck-color-switch-button-off-background: #939393;
    --ck-color-focus-border: hsl(hsl(243, 75%, 59%));
    --ck-color-focus-border-coordinates: hsl(243, 75%, 59%);
    --ck-color-focus-outer-shadow: hsl(239, 84%, 67%);
    --ck-color-focus-disabled-shadow: rgba(123, 119, 248, 0.3);
    --ck-color-focus-error-shadow: rgba(255, 64, 31, 0.3);

    /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */
    --ck-color-dropdown-panel-background: var(--ck-custom-popover-background);
    --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-dialog class colors. ----------------------------------- */
    --ck-color-dialog-background: var(--ck-custom-popover-background);
    --ck-color-dialog-form-header-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */
    --ck-color-split-button-hover-background: var(
      --ck-color-button-default-hover-background
    );
    --ck-color-split-button-hover-border: var(--ck-custom-foreground);

    /* -- Overrides the default .ck-input class colors. ----------------------------------------- */
    --ck-color-input-background: var(--ck-custom-background);
    --ck-color-input-border: hsl(257, 3%, 43%);
    --ck-color-input-text: hsl(0, 0%, 98%);
    --ck-color-input-disabled-background: hsl(255, 4%, 21%);
    --ck-color-input-disabled-border: hsl(250, 3%, 38%);
    --ck-color-input-disabled-text: hsl(0, 0%, 78%);

    /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */
    --ck-color-labeled-field-label-background: var(--ck-custom-background);

    /* -- Overrides the default .ck-list class colors. ------------------------------------------ */
    --ck-color-list-background: var(--ck-custom-background);
    --ck-color-list-button-hover-background: var(--ck-custom-foreground);
    --ck-color-list-button-on-background: var(
      --ck-color-button-action-active-background
    );
    --ck-color-list-button-on-text: var(--ck-custom-white);

    /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */
    --ck-color-panel-background: var(--ck-custom-panel-background);
    --ck-color-panel-border: var(--ck-custom-border);

    /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */
    --ck-color-toolbar-background: var(--ck-custom-background);
    /* --ck-color-toolbar-border: var(--ck-custom-border); */
    --ck-color-toolbar-border: hsl(0, 0%, 41%);

    /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */
    --ck-color-tooltip-background: hsl(0, 0%, 9%);
    --ck-color-tooltip-text: hsl(0, 0%, 93%);

    /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);

    /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */
    --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
    --ck-color-widget-hover-border: hsl(43, 100%, 68%);
    --ck-color-widget-editable-focus-background: var(--ck-custom-white);

    /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */
    --ck-color-link-default: hsl(239, 100%, 75%);

    --ck-color-thread-header-background: hsl(56, 41%, 22%);
    --ck-color-thread-header-active-background: hsl(55, 62%, 23%);

    --ck-color-comment-marker: hsla(55, 80%, 30%, 0.3);
    --ck-color-comment-marker-active: hsla(55, 80%, 30%, 0.5);

    --ck-color-annotation-counter-comment: hsl(55, 100%, 32%);
    --ck-color-annotation-counter-suggestion-insertion: hsl(142, 76%, 36%);
    --ck-color-annotation-counter-suggestion-deletion: hsl(0, 76%, 37%);
    /*--ck-color-annotation-counter-suggestion-format: #5ac1d8; */

    --ck-color-comment-box-border: hsl(55, 100%, 35%);
    --ck-color-suggestion-box-insertion-border: hsl(142, 76%, 39%);
    --ck-color-suggestion-box-deletion-border: hsl(0, 76%, 40%);
    /*--ck-color-suggestion-box-format-border: #5ac1d8; */

    --ck-color-annotation-wrapper-background: var(--ck-custom-panel-background);
    --ck-color-annotation-wrapper-drop-shadow: 0 1px 1px 1px #171717;

    --ck-color-comment-background: #262626;
    --ck-color-comment-separator: hsl(300, 1%, 39%);
    --ck-color-comment-remove-background: hsl(5, 39%, 23%);
    --ck-color-comment-input-background: var(--ck-color-comment-background);
    --ck-color-comment-count: hsl(243, 83%, 66%);

    --ck-color-annotation-info: #ffffff;

    /* Suggestions */
    --ck-color-suggestion-marker-insertion-background: hsla(142, 71%, 45%, 0.1);
    --ck-color-suggestion-marker-insertion-background-active: hsla(
      102,
      51%,
      48%,
      0.35
    );
    --ck-color-suggestion-marker-deletion-background: hsla(
      0,
      91%,
      71%,
      0.2
    ) !important;
    --ck-color-suggestion-marker-deletion-background-active: hsla(
      358,
      78%,
      46%,
      0.35
    ) !important;
    --ck-color-suggestion-marker-deletion-stroke: rgba(
      252,
      166,
      166,
      0.35
    ) !important;
  }

  .ck {
    --ck-ai-form-view-width: 700px;
    --ck-ai-form-content-height: 200px;
    --ck-ai-form-content-background: var(--ck-custom-panel-background);
    --ck-ai-form-error-background: hsla(0, 63%, 31%, 0.7);
  }

  .ck {
    --ck-revision-history-revision-highlighted-background-color: hsl(
      239,
      84%,
      67%
    );

    --ck-revision-history-revision-background-color: hsl(0, 0%, 15%);
    --ck-revision-history-revision-selected-background-color: hsl(
      243,
      75%,
      59%
    );
    --ck-revision-history-revision-border-color: hsl(0, 0%, 15%);
    --ck-revision-history-revision-selected-text-color: var(--ck-custom-white);
    /* End Suggestions */
  }
}

/*
    --nextui-content1: hsl(240 5.88% 10%);
    --nextui-content1-foreground: hsl(0 0% 98.04%);
    --nextui-content2: hsl(240 3.7% 15.88%);
    --nextui-content2-foreground: hsl(240 4.76% 95.88%);
    --nextui-content3: hsl(240 5.26% 26.08%);
    --nextui-content3-foreground: hsl(240 5.88% 90%);
    --nextui-content4: hsl(240 5.2% 33.92%);
    --nextui-content4-foreground: hsl(240 4.88% 83.92%); */
