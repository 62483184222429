.react-pdf__Page {
  @apply text-center;
}

.react-pdf__Page__textContent {
  text-align: initial;
  @apply bg-white;
  @apply p-12;
}

.react-pdf__Page__canvas {
  @apply mx-auto;

  width: 100% !important;
  height: auto !important;
}

.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
  @apply shadow-md;
}

.react-pdf__Page__annotations.annotationLayer {
  @apply hidden;
}

.react-pdf__Page__textContent {
  display: none;
}