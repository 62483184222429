html,
body {
  @apply fixed m-0 h-full w-full overflow-hidden p-0;
}

html {
  @apply bg-gray-100 #{!important};
  &.dark {
    @apply bg-black #{!important};
  }
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-figtree;
}

/* Scrollbar customization */
body {
  &.customScrollbarLayout {
    --scrollbar-width: 12px;
    --scrollbar-min-size: 32px;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color) transparent;

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-color);
      opacity: 0.2;
      border-radius: var(--scrollbar-width);
      border: 3px solid transparent;
      background-clip: content-box;
    }

    ::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-width);
    }

    * {
      scrollbar-width: thin;
      scrollbar-color: var(--scrollbar-color) transparent;
    }
  }
}

div#__next {
  height: 100%;
  margin: 0;
  @apply overflow-hidden;
}

body {
  margin: 0;
}

div#__next {
  height: 100%;
}

a:not([role='menuitem']):not([role='option']):not(.ui) {
  @apply text-indigo-600;
  @apply dark:text-indigo-400;
  @apply font-medium;
  transition: none;
}

a:not([role='menuitem']):not([role='option']):not(.ui):hover {
  @apply text-indigo-500;
  @apply dark:text-indigo-300;
}

input:not([role='switch']):not([data-slot='input']):not(.ck-input),
textarea:not(.ui),
select:not(.ui) {
  @apply bg-white/30;
  @apply dark:bg-black/25;
  @apply border;
  /* @apply border-transparent; */
  /* @apply dark:border-none; */
  @apply border-gray-300;
  @apply dark:border-gray-700;
}

select:not(.ui):hover,
select:not(.ui):hover:focus {
  @apply bg-white/40;
  @apply border-gray-400;
  @apply dark:border-gray-600;
  @apply dark:bg-black/30;
}

input:focus:not([role='switch']):not(.ck-input),
textarea:focus:not(.ui),
select:not(.ui):focus {
  @apply outline;
  @apply outline-1;
  @apply outline-black/10;
  @apply dark:outline-white/10;
  @apply border-black/30;
  @apply dark:border-white/30;
}

input:disabled:not(.ui):not([role='switch']):not(.ck-input),
textarea:disabled:not(.ui),
select:not(.ui):disabled {
  @apply bg-black/10;
  @apply dark:bg-white/10;
  @apply border-none;
  @apply opacity-50;
  @apply cursor-not-allowed;
}

input.red:not([role='switch']):not(.ck-input),
textarea.red {
  @apply border-yellow-400;
}

b,
strong {
  @apply font-medium;
}

input:not([role='switch']):not([data-slot='input']):not(.ck-input),
select:not(.ui),
textarea:not(.ui) {
  @apply outline-none;
  @apply py-2;
  @apply px-3;
  @apply rounded-md;
  box-shadow: none;
}

h1:not(.ui) {
  @apply text-3xl;
}

h2:not(.ui) {
  @apply text-2xl;
}

h3:not(.ui),
h4:not(.ui) {
  @apply text-primary;
  @apply font-medium;
}

h3:not(.ui),
h4:not(.ui) {
  @apply text-xl;
}

h5:not(.ui),
h6:not(.ui) {
  @apply text-lg;
  @apply font-semibold;
  @apply text-secondary;
}

.blurred {
  color: transparent;
  user-select: none;
  text-shadow: 0 0 8px #000;
}

.dark .blurred {
  text-shadow: 0 0 8px #fff;
}

::selection {
  @apply bg-indigo-200;
  @apply bg-opacity-40;
}

.text-secondary:not(.ui) {
  @apply text-gray-600;
  @apply dark:text-gray-400;
}

.text-tertiary {
  @apply text-gray-500;
  @apply dark:text-gray-500;
}

.text-red {
  @apply text-red-500;
  @apply dark:text-red-400;
}

.text-green {
  @apply text-green-600;
  @apply dark:text-green-500;
}

.card {
  @apply rounded-lg;
  @apply bg-white;
  @apply hover:bg-opacity-90;

  @apply dark:bg-gray-800;
  @apply dark:bg-opacity-70;
  @apply dark:hover:bg-opacity-90;

  @apply transition-shadow;
  transition:
    background 150ms ease-in,
    transform 150ms ease-in;

  @apply shadow-md;
  @apply hover:shadow-lg;
}

.card:hover {
  transform: scale(1.002);
}

.card.active {
  @apply shadow-md;
  @apply cursor-auto;
  @apply bg-opacity-100;
  @apply hover:bg-opacity-100;
}

.card.active:hover {
  transform: none;
}

.ellipsis-line-3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

strong {
  font-weight: 600;
}

.toggle-btn {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.toggle-btn::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-btn.active {
  background-color: #66bb6a;
}

.toggle-btn.active::after {
  transform: translateX(20px);
}

.customDrawer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.customDrawer {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

/* For Clauses drawer */
.diff-insertion {
  @apply text-green-700;
  @apply dark:text-green-400;
  @apply bg-green-300/50;
  @apply dark:bg-green-600/10;
}

.diff-deletion {
  @apply text-red-700;
  @apply line-through;
  @apply dark:text-red-400;
  @apply bg-red-300/40;
  @apply dark:bg-red-600/10;
}

.ds-calendar-fix-class {
  div[data-slot='input-wrapper'] {
    @apply px-3 #{!important};
  }
}
.ds-calendar-content-fix-class {
  // td[role='gridcell'] > span {
  //   @apply dark:data-[hover=true]:bg-primary-50 dark:data-[hover=true]:text-primary #{!important};
  //   @apply dark:data-[selected=true]:data-[range-selection=true]:before:bg-primary-50 dark:data-[selected=true]:data-[range-selection=true]:text-primary #{!important};
  //   /* First and last node of the range selection */
  //   @apply dark:data-[selected=true]:data-[selection-start=true]:data-[range-selection=true]:text-primary-foreground #{!important};
  //   @apply dark:data-[selected=true]:data-[selection-end=true]:data-[range-selection=true]:text-primary-foreground #{!important};
  // }

  div[data-slot='picker-month-list'],
  div[data-slot='picker-year-list'] {
    @apply scrollbar-hide #{!important};
  }
}

.ds-special-character-wrapper > :first-child {
  @apply text-base leading-none #{!important};
}

/* New globals */

[contenteditable='true']:empty:before {
  content: attr(data-placeholder);
  @apply text-foreground-400 pointer-events-none inline-block bg-transparent;
}

/* End New globals */

/* End For Clauses drawer */
@layer base {
  :root {
    /* Scrollbar customization */
    body {
      &.customScrollbarLayout {
        --scrollbar-color: #9e9ea0;

        ::-webkit-scrollbar-corner {
          background: rgb(251, 251, 251);
        }
      }
    }
  }

  :root[class~='dark'] {
    /* Scrollbar customization */
    body {
      &.customScrollbarLayout {
        --scrollbar-color: #5a5b5d;

        ::-webkit-scrollbar-corner {
          background: rgb(15, 16, 17);
        }
      }
    }
  }
}
